import { FC, useEffect, useMemo, useState } from 'react';
// import type { ChangeEvent } from 'react';
import { List, Map } from 'immutable';
import pluralize from 'pluralize';
import {
  Copy20,
  DecisionTree16,
  ChevronDown16,
  ChevronUp16,
  CategoryAdd20,
  Warning16,
  Delete20,
} from '@carbon/icons-react';
import classNames from 'classnames';
import { SearchInput } from 'components';
import { debounce } from 'lodash';
import { getTemplatePreview, getTemplates, initDispatchedWS, searchTemplates, updateTree } from './Sync';
import { Page } from './Page';
import { TreePreview, cyToPath } from './Tree';
import { defaultState, EventNode, FailureMode } from './PROACT';
import { templateToNode } from './Tree';
import { Button } from './Buttons';

const Template: FC<{ onClick: any; title: string; isSelected?: boolean; score?: number } & Record<string, unknown>> = ({
  onClick,
  title,
  isSelected,
  ...props
}) => {
  return (
    <div>
      <div
        className={classNames('ml3 pv2 dim pointer flex items-center', {
          white: !isSelected,
          blue: isSelected,
        })}
        onClick={onClick}
      >
        <DecisionTree16 className="rotate-90" />
        <div className="ml2 dib">{title}</div>
        {process.env.NODE_ENV !== 'production' &&
        props.score !== undefined &&
        window.location.hash.includes('?debug') ? (
          <span
            className="bg-yellow fg--black-80 ml-auto mr3 fw-bold f6"
            style={{ padding: '2px 4px', borderRadius: 4 }}
          >
            {props.score}
          </span>
        ) : null}
      </div>
    </div>
  );
};

const TemplateOption = ({ onClick, isSelected, title }) => {
  return (
    <div>
      <div
        className={classNames('ml3 pv2 dim pointer flex items-center', {
          white: !isSelected,
          blue: isSelected,
        })}
        onClick={onClick}
      >
        <DecisionTree16 className="rotate-90" />
        <div className="ml2 dib">{title}</div>
      </div>
    </div>
  );
};

const TemplateComponent = ({ state, dispatch, component, templates, onClick }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="ml3 pv1">
      <div className="f5 mb2 dim pointer" onClick={() => setShow(s => !s)}>
        <div className="dib mr2">{show ? <ChevronDown16 /> : <ChevronUp16 />}</div>
        {component}
      </div>
      {show &&
        templates
          .sortBy(t => t.get('title'))
          .map(template => {
            const templateUuid = template.get('templateUuid');
            return (
              <TemplateOption
                key={`${template.get('title')}-${template.get('index')}`}
                isSelected={state.get('selectedTemplateUuid') === templateUuid}
                // uuid={template.get('templateUuid')}
                // onClick={() =>
                //   dispatch(Map({ type: 'FETCH_TEMPLATE_PREVIEW', templateUuid: template.get('templateUuid') }))
                // }
                onClick={() => onClick(templateUuid)}
                title={template.get('title')}
              />
            );
          })}
    </div>
  );
};

const TemplateHeader: FC<{ category: string; isExpanded?: boolean }> = ({ category, isExpanded = false, children }) => {
  const [show, setShow] = useState(isExpanded);

  useEffect(() => {
    if (isExpanded !== show) {
      return setShow(isExpanded);
    }

    return;
  }, [isExpanded]);

  // console.info('TemplateHeader', { isExpanded, show });

  return (
    <div className="pl2 pv2 white-80 mb2" style={{ userSelect: 'none' }}>
      <div className="f4 mb2 dim pointer" onClick={() => setShow(s => !s)}>
        <div className="dib mr2">{show ? <ChevronDown16 /> : <ChevronUp16 />}</div>
        {category}
      </div>
      {show && children}
    </div>
  );
};

const TemplateCategory: FC<{ category: string; isExpanded?: boolean } & Record<string, unknown>> = ({
  state,
  dispatch,
  category,
  components,
  isExpanded = false,
  onSelectTemplate,
}) => {
  return (
    <TemplateHeader category={category} isExpanded={isExpanded}>
      {components
        // @ts-expect-error
        .entrySeq()
        .sortBy(([k]) => k)
        .map(([component, templates]) => (
          <TemplateComponent
            key={component}
            state={state}
            dispatch={dispatch}
            component={component}
            onClick={onSelectTemplate}
            templates={templates}
          />
        ))}
    </TemplateHeader>
  );
};

type Match = { item: { treeUuid?: string; templateUuid?: string; title: string }; score: number };
type TemplateMatch = { item: { title: string; templateUuid: string }; score: number };
type AnalysisMatch = { item: { title: string; treeUuid: string }; score: number };
// type MatchingAnalysis = { treeUuid: string; score: number };
type SearchResults = Match[];

const QuotationWrapper: FC<{ color?: string }> = ({ color = 'gray', children }) => {
  return (
    <>
      <span className={color}>&#8220;</span>
      {children}
      <span className={color}>&#8220;</span>
    </>
  );
};

const NoResultsMessage: FC<{ query?: string }> = ({ query }) => {
  return (
    <div className="flex items-center justify-start pv2 pl2">
      <Warning16 className="mr2" />
      <span className="white fw-light f6">
        {!query || query === '' ? (
          <>No results</>
        ) : (
          <>
            No results for
            <QuotationWrapper color="gray">
              <span className="fw-medium">{query}</span>
            </QuotationWrapper>
          </>
        )}
      </span>
    </div>
  );
};

// export function isPSGCWorkOrder(wo: TemplateMatch): wo is TemplateMatch {}

function isMatchedTemplate(resultObj: Match): resultObj is TemplateMatch {
  if (!resultObj.item) return false;

  return 'templateUuid' in resultObj.item;
}

function isMatchedAnalysis(resultObj: Match): resultObj is AnalysisMatch {
  if (!resultObj.item) return false;

  return 'treeUuid' in resultObj.item;
}

const TemplateSelector = ({ state, dispatch }) => {
  const ws = state.get('ws');
  const token = state.get('token');
  const [internal, setInternal] = useState([]);
  const templates = state
    .get('templates', List())
    .map((t, idx) => t.set('index', idx))
    .groupBy(x => x.get('category'))
    .map(v => v.groupBy(x => x.get('component')))
    .sortBy(t => (t.title || 'Untitled Analysis').toLowerCase());

  useEffect(() => {
    if (!token) return;

    async function fetchInternalTemplates() {
      try {
        const data = await getTemplates({ token });
        const prepared = List(data)
          /* @ts-expect-error */
          .sortBy(t => (t.title || 'Untitled Analysis').toLowerCase())
          .toJS();

        setInternal(prepared);
      } catch (error) {}
    }

    fetchInternalTemplates();
  }, [token]);

  // const templatesList = state.get('templates', List()).toJS();
  const rawAnalyses = state.get('trees', Map()).toJS();

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResults>([]);
  // const [matchingTemplates, setMatchingTemplates] = useState([]);

  const handleSearch = e => {
    if (e.target.value === '' || !e.target.value) {
      setSearchQuery('');
      return setSearchResults([]);
    }

    setSearchQuery(e.target.value);
    searchTemplates({ token, query: e.target.value })
      .then(results => setSearchResults(results))
      .catch(error => {
        console.error('[searchTemplates]:', error);
      });
  };

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const matchingTemplates = useMemo(
    () =>
      searchResults.filter(isMatchedTemplate).map(m => {
        return m;
      }),
    [searchResults],
  );

  const matchingAnalyses = useMemo(
    () =>
      searchResults
        .filter(isMatchedAnalysis)
        .map(m => {
          return {
            ...m,
            item: {
              ...m.item,
              title: m.item.title || 'Untitled Analysis',
            },
          };
        })
        .filter(m => m),
    [searchResults],
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  // cancel our debounced search handler if we're unmounted
  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);

  useEffect(() => {
    if (ws) {
      dispatch(Map({ type: 'FETCH_TEMPLATE_LIST' }));
      dispatch(Map({ type: 'LIST' }));
      dispatch(Map({ type: 'GET_ORGANIZATION' }));
    }
  }, [ws, dispatch]);

  const username = state.get('username');
  const user = state.getIn(['users', username]);
  const categoryLabel = state.getIn(['organization', 'companyName']);
  const handlePreviewTemplate = (uuid: string) => {
    getTemplatePreview({ token, uuid })
      .then(t => {
        const isInternal = t.treeUuid !== undefined;
        const uuid = isInternal ? t.treeUuid : t.templateUuid;

        dispatch(Map({ type: 'SET_SELECTED_TEMPLATE', uuid, template: t }));
      })
      .catch(e => {});
  };

  return (
    <div style={{ isolation: 'isolate' }}>
      <div className="bg-black-90 top-0 left-0 bottom-0 w6 absolute white bt b--white b--white-30 shadow-1 z-3 overflow-y-auto">
        <div className="pa3">
          <SearchInput
            onClear={() => dispatch({ type: 'CLEAR_TEMPLATE_PREVIEW' })}
            onChange={debouncedHandleSearch}
            defaultValue={searchQuery}
            placeholder="What are you looking for?"
          />
        </div>

        <TemplateHeader category={categoryLabel} isExpanded={searchQuery !== null && searchQuery !== ''}>
          {searchQuery !== '' && searchQuery && matchingAnalyses.length < 1 ? (
            <NoResultsMessage query={searchQuery} />
          ) : (
            <>
              {matchingAnalyses.length > 0 ? (
                <>
                  <div className="flex items-center justify-start pv2 pl2">
                    <span className="white ml1 fw-light f6">
                      {matchingAnalyses.length} {pluralize('result', matchingAnalyses.length)} for
                      <QuotationWrapper>
                        <span className="fw-medium">{searchQuery}</span>
                      </QuotationWrapper>
                    </span>
                  </div>
                  {matchingAnalyses.map(r => (
                    <Template
                      score={r.score}
                      key={r.item.treeUuid}
                      onClick={() => handlePreviewTemplate(r.item.treeUuid)}
                      isSelected={state.get('selectedTemplateUuid') === r.item.treeUuid}
                      title={r.item.title}
                    />
                  ))}
                </>
              ) : (
                <>
                  {internal.map(t => {
                    return (
                      <Template
                        score={t.score}
                        key={t.treeUuid}
                        title={t.title}
                        isSelected={state.get('selectedTemplateUuid') === t.treeUuid}
                        onClick={() => handlePreviewTemplate(t.treeUuid)}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </TemplateHeader>
        {user && user.getIn(['orgFeatures', 'templates'], false) && (
          <TemplateHeader category="PROACT&#174;" isExpanded={searchQuery !== null && searchQuery !== ''}>
            {searchQuery !== '' && searchQuery && matchingTemplates.length < 1 ? (
              <NoResultsMessage query={searchQuery} />
            ) : (
              <>
                {matchingTemplates.length > 0 ? (
                  <>
                    <div className="flex items-center justify-start pv2 pl2">
                      <span className="white ml1 fw-light f6">
                        {matchingTemplates.length} {pluralize('result', matchingTemplates.length)} for
                        <QuotationWrapper>
                          <span className="fw-medium">{searchQuery}</span>
                        </QuotationWrapper>
                      </span>
                    </div>
                    {matchingTemplates.map(t => (
                      <Template
                        score={t.score}
                        key={t.item.templateUuid}
                        // onClick={() =>
                        //   dispatch(Map({ type: 'FETCH_TEMPLATE_PREVIEW', templateUuid: t.item.templateUuid }))
                        // }
                        onClick={() => handlePreviewTemplate(t.item.templateUuid)}
                        isSelected={state.get('selectedTemplateUuid') === t.item.templateUuid}
                        title={t.item.title}
                        // uuid={t.item.templateUuid}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {templates
                      .entrySeq()
                      .sortBy(x => x[0])
                      .map(([category, components]) => (
                        <TemplateCategory
                          key={category}
                          onSelectTemplate={handlePreviewTemplate}
                          state={state}
                          dispatch={dispatch}
                          category={category}
                          components={components}
                        />
                      ))}
                  </>
                )}
              </>
            )}
          </TemplateHeader>
        )}
      </div>
    </div>
  );
};

const PROACTState = defaultState();

const gridSettings = {
  drawGrid: true,
  gridColor: 'rgba(100, 100, 255, 0.1)',
  gridSpacing: 20,
  snapToGridOnRelease: false,
};

const EmptyTemplatePreviewer = () => {
  return (
    <div className="black flex justify-center items-center f4 h-100 bg-grid">
      <div className="i18n bg-black ph3 pv2 white mb4">Select a template on the left to preview.</div>
    </div>
  );
};

const addEventAndMode = elements =>
  EventNode().set('children', List([FailureMode().set('children', List([elements]))]));

const TemplatePreviewer = ({ dispatch, isAdmin, templateUuid, token, template, isInternal, methodology }) => {
  const stylesheet = PROACTState.getIn(['view', 'stylesheet']);
  const layout = PROACTState.getIn(['view', 'layout']);
  const elements = isInternal ? template.get('elements') : template;
  const hasElements = !!elements;

  const [currentCy, setCurrentCy] = useState(null);

  const [selectedPath, setSelectedPath] = useState(null);
  useEffect(() => {
    setSelectedPath(null);
  }, [template]);

  return (
    <div className="bg-white absolute top-0 right-0 h-100 z-1" style={{ width: 'calc(100% - 24rem)' }}>
      {hasElements && (
        <div className="top-0 left-0 ml3 mt3 absolute z-1">
          <Button
            text="Launch in New Analysis"
            icon={<Copy20 />}
            className="mb2"
            onClick={() => {
              const newElements = isInternal ? elements : addEventAndMode(elements);

              dispatch(Map({ type: 'CLEAR_TEMPLATE_PREVIEW' }));

              dispatch(
                Map({
                  type: 'NEW_TREE',
                  events: List(),
                  elements: newElements,
                  tree: Map({ methodology }),
                }),
              );
            }}
          />
          {isAdmin && isInternal ? (
            <Button
              text="Delete Template"
              disabled={!isAdmin}
              icon={<Delete20 />}
              className="mb2"
              theme="danger"
              // onClick={() => {
              //   dispatch(
              //     Map({
              //       type: 'UPDATE_TREE',
              //       treeUuid: templateUuid,
              //       updates: {
              //         isTemplate: false,
              //       },
              //     }),
              //   );
              //   dispatch(
              //     Map({
              //       type: 'CLEAR_TEMPLATE_PREVIEW',
              //     }),
              //   );
              // }}
              onClick={() => {
                return updateTree({
                  token,
                  treeUuid: templateUuid,
                  updates: { isTemplate: false },
                })
                  .then(results => {
                    if (results.ok) {
                      dispatch(
                        Map({
                          type: 'SHOW_TOAST',
                          message: 'Successfully removed from template library.',
                          style: 'SUCCESS',
                        }),
                      );
                      dispatch({
                        type: 'TREE_UPDATED',
                        treeUuid: templateUuid,
                        updates: { isTemplate: false },
                      });
                      return dispatch(
                        Map({
                          type: 'CLEAR_TEMPLATE_PREVIEW',
                        }),
                      );
                    } else {
                      return dispatch(
                        Map({
                          type: 'SHOW_TOAST',
                          message: results.error,
                          duration: 4000,
                          style: 'ERROR',
                        }),
                      );
                    }
                    // }, 5000);
                  })
                  .catch(e => console.error(e));
              }}
            />
          ) : null}
          <Button
            text="Copy Snippet"
            disabled={!selectedPath}
            icon={<CategoryAdd20 />}
            onClick={() => {
              dispatch(
                Map({
                  type: 'COPY_SUBTREE',
                  elements: elements.getIn(selectedPath),
                }),
              );
              dispatch(
                Map({
                  type: 'SHOW_TOAST',
                  style: 'SUCCESS',
                  message: 'Snippet has been added to your clipboard. Paste into a tree to utilize in your analysis.',
                }),
              );
            }}
          />
        </div>
      )}
      {hasElements ? (
        <TreePreview
          // dispatch={dispatch}
          onCyChange={cy => {
            if (currentCy !== cy) {
              setCurrentCy(cy);
              cy.on('tapselect', 'node', event => {
                setSelectedPath(cyToPath(event.target));
              });

              cy.on('add', 'node', () => {
                // @ts-ignore
                cy.layout(layout.toJS()).run();
                // @ts-ignore
                requestAnimationFrame(() => cy.layout(layout.toJS()).run());
              });

              cy.on('select', 'node', e => cy.elements().not(e.target).unselect());

              cy.on('tapunselect', 'node', () => {
                setSelectedPath(null);
              });
              cy.gridGuide(gridSettings);
            }
          }}
          elements={elements}
          stylesheet={stylesheet}
          layout={layout}
        />
      ) : (
        <EmptyTemplatePreviewer />
      )}
    </div>
  );
};

export const Templates = ({ state, dispatch }) => {
  const ws = state.get('ws');

  useEffect(() => {
    if (!ws) {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          dispatch(Map({ type: 'SET_URL', url: '/templates' }));
        }
      });
    }

    // when an internal template is selected and the previewer begins to render, state.trees is usually missing.
    // adding another LIST here to make sure there's no 'trees' gap in the state tree
    if (!state.get('trees')) {
      dispatch(Map({ type: 'LIST' }));
    }

    dispatch(Map({ type: 'CLEAR_TEMPLATE_PREVIEW' }));
  }, [ws, dispatch]);

  const selectedTemplate = state.getIn(['selectedTemplate'], null);
  const selectedTemplateUuid = state.get('selectedTemplateUuid', null);
  const token = state.get('token');
  const isInternalTemplate = state.get('selectedTemplateIsInternal');
  const roles = state.getIn(['users', state.get('username', null), 'roles'], List());
  const isAdmin = roles.has('ADMIN');

  const methodology = isInternalTemplate
    ? state.getIn(['trees', selectedTemplate.get('treeUuid'), 'methodology'])
    : 'PROACT';

  // const templateNodeCollection = selectedTemplate ? templateToNode(selectedTemplate) : null;

  return (
    <Page title="Template Library" state={state} dispatch={dispatch}>
      <div className="ba absolute left-0 bottom-0 right-0" style={{ top: '9.5rem' }}>
        <TemplateSelector state={state} dispatch={dispatch} />
        <TemplatePreviewer
          isAdmin={isAdmin}
          token={token}
          templateUuid={selectedTemplateUuid}
          methodology={methodology}
          dispatch={dispatch}
          template={
            selectedTemplate && !isInternalTemplate
              ? templateToNode(selectedTemplate.get('elements'))
              : selectedTemplate
          }
          isInternal={isInternalTemplate}
        />
      </div>
    </Page>
  );
};
