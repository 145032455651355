import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
// import pluralize from 'pluralize';
// import cx from 'classnames';
import { List, Map, Set, Range } from 'immutable';
import {
  MailAll20,
  UserFollow20,
  User20,
  Error20,
  UserAdmin20,
  Reset20,
  IbmSecurity20,
  NextFilled20,
  PreviousFilled20,
  // Renew32,
  Edit20,
  // Add20,
  // TrashCan16,
  // Edit16,
  TrashCan20,
  // Close20,
} from '@carbon/icons-react';
// import ReactTooltip from 'react-tooltip';
import { validate } from 'email-validator';
// import { isEmpty } from 'lodash';

// import { Popover } from 'components';
import { _ } from 'utils';
import { Page } from './Page';
import { Blade } from './Blade';
import { Button } from './Buttons';
// import { FileUploader } from './FileInputs';
import { Input } from './TextInputs';
import { Avatar } from './Nav';
import { deleteOrgMember, deleteOrgInvite, initDispatchedWS } from './Sync';

const minPhoneLength = 4;
const maxPhoneLength = 20;

const DeleteUserButton = ({ handleDelete, isInvite }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const handleShowConfirmation = () => setShowDeleteConfirmation(true);
  const handleCancelConfirmation = () => setShowDeleteConfirmation(false);
  const handleDeleteAndHideConfirmation = () => {
    handleDelete();
    handleCancelConfirmation();
  };

  /*
    set up a handler for the initial delete button
    that shows the confirmation if it's not already visible
    and closes it otherwise
  */
  const handleToggleConfirmation = () => {
    if (showDeleteConfirmation) {
      return handleCancelConfirmation();
    }

    return handleShowConfirmation();
  };

  return (
    <div className="confirmation-container pa2 pointer">
      <button
        data-tip={isInvite ? 'Delete invitation' : 'Delete user'}
        className="bg-transparent flex items-center justify-center"
        style={{ zIndex: 99999999999999 }}
        onClick={handleToggleConfirmation}
      >
        <TrashCan20 />
      </button>
      <div
        className={classNames(
          { dn: !showDeleteConfirmation },
          {
            'flex items-center justify-start inline-confirmation flex-column': showDeleteConfirmation,
          },
        )}
      >
        <span className="inline-confirmation-label">
          Are you sure you want to delete this {isInvite ? 'invitation' : 'user'}? You cannot undo this action.
        </span>
        <div className="inline-confirmation-actions flex items-center justify-center">
          <button className="inline-confirmation-actions-item red fw6" onClick={handleDeleteAndHideConfirmation}>
            Yes
          </button>
          <span className="inline-confirmation-actions-item separator o-65">/</span>
          <button className="inline-confirmation-actions-item black-60" onClick={handleCancelConfirmation}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const UserCard = ({ state, dispatch, username, canAssign, groups, setSelectedUser }) => {
  const i18n = state.get('i18n');
  const user = state.getIn(['users', username], Map());
  const fullName = user.get('fullName', '');
  const jobTitle = user.get('jobTitle', ' ');
  const email = user.get('email');
  const phoneNumber = user.get('phone', ' ');
  const roles = user.get('roles', List());
  const token = state.get('token');
  const isAdmin = roles.has('ADMIN');
  const isPaid = roles.has('PAID');
  const isInvited = roles.has('INVITED');
  const iAmAdmin = state.getIn(['users', state.get('username'), 'roles'], Set()).has('ADMIN');
  const isMe = state.get('username') === username;

  const onDemote = () => dispatch(Map({ type: 'DEMOTE_MEMBER', member: username }));
  const onPromote = () => dispatch(Map({ type: 'PROMOTE_MEMBER', member: username }));
  const onRevoke = () => dispatch(Map({ type: 'REVOKE_MEMBER', member: username }));
  const onAssign = () => dispatch(Map({ type: 'ASSIGN_MEMBER', member: username }));
  const onReset = () => dispatch(Map({ type: 'RESET_MEMBER', member: username }));
  const onResend = () => dispatch(Map({ type: 'RESEND_INVITE', email: email }));
  const onDeleteInvite = async () => {
    deleteOrgInvite({ token, member: username })
      .then(results => {
        // setTimeout(() => {
        if (results.ok) {
          return dispatch(Map({ type: 'DELETE_ORG_INVITE', member: username }));
        } else {
          return dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: results.error,
              duration: 4000,
              style: 'ERROR',
            }),
          );
        }
        // }, 5000);
      })
      .catch(e => console.error(e));
  };
  const onDelete = async () => {
    deleteOrgMember({ token, member: username })
      .then(results => {
        // setTimeout(() => {
        if (results.ok) {
          return dispatch(Map({ type: 'DELETE_ORG_MEMBER', member: username }));
        } else {
          return dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: results.error,
              duration: 4000,
              style: 'ERROR',
            }),
          );
        }
        // }, 5000);
      })
      .catch(e => console.error(e));

    // dispatch(Map({ type: 'DELETE_MEMBER', member: username }));
  };

  return (
    <>
      <tr className="striped--light-gray">
        <td className="pv2 ph3 ba b--black-70 w-20">
          <div className="custom_flex flex ">
            <Avatar state={state} dispatch={dispatch} height={42} username={username} />
            <div className={'fw7 ph2'}>{fullName}</div>
          </div>
        </td>
        <td className="pv2 ph3 ba b--black-70 w-20">
          <div className={'mb1 f6'}>{email}</div>
        </td>

        <td className="pv2 ph3 ba b--black-70 w-20">
          {phoneNumber ? <div className={'mb1 f6'}>{phoneNumber}</div> : <span className="f6 fg--black-60">N/A</span>}
        </td>

        <td className="pv2 ph3 ba b--black-70 w-20">
          {jobTitle ? <div className="mb1 f6">{jobTitle}</div> : <span className="fg--black-60 f6">N/A</span>}
        </td>
        <td className="pv2 ph3 ba b--black-70 w-20">
          <div className="custom_flex flex">
            <div
              className={classNames('f6 bg-navy white ba ml2 pa1 fw7', {
                dn: !isAdmin,
              })}
            >
              Admin
            </div>
            <div
              className={classNames('f6 bg-dark-red white ba ml2 pa1 fw7', {
                dn: isPaid || isInvited,
              })}
            >
              Unlicensed
            </div>
            <div
              className={classNames('f6 bg-dark-green white ba ml2 pa1 fw7', {
                dn: !isPaid || isAdmin,
              })}
            >
              Licensed
            </div>
            <div
              className={classNames('f6 bg-orange white ba ml2 pa1 fw7', {
                dn: !isInvited,
              })}
            >
              Invited
            </div>
          </div>
        </td>
        <td className="pv2 ph3 ba b--black-70">
          {groups && groups.size > 0 ? (
            groups.map((g, idx) => (
              <span
                key={g.get('groupUuid')}
                className={classNames('db', {
                  mb3: groups.size > 1 && ++idx !== groups.size,
                })}
                title={g.get('admins').includes(user.get('username')) ? 'Admin' : 'Member'}
              >
                {g.get('name')}
              </span>
            ))
          ) : (
            <span className="fg--black-60 f6">N/A</span>
          )}
        </td>
        <td
          className={classNames('pv2 ph3 ba b--black-70 w-20', {
            dn: !iAmAdmin,
          })}
        >
          <div
            className={classNames('items-center', {
              flex: iAmAdmin,
              dn: !iAmAdmin,
            })}
          >
            <div
              className={classNames('fill--red mr2 dim pa2 pointer', {
                dn: !isPaid || isInvited,
              })}
              onClick={onRevoke}
              data-tip="Remove user license"
            >
              <Error20 />
            </div>
            <div
              className={classNames('mr2 dim pa2 pointer', {
                dn: isInvited,
              })}
              onClick={onReset}
              data-tip={_(i18n, 'Send this user a password reset email')}
            >
              <Reset20 />
            </div>
            <div
              className={classNames('dim pa2 pointer', {
                dn: isPaid || !canAssign || isInvited,
              })}
              onClick={onAssign}
              data-tip={_(i18n, 'Grant a license')}
            >
              <UserAdmin20 />
            </div>
            <div
              className={classNames('dim pa2 pointer', {
                dn: !isAdmin || isMe || isInvited,
              })}
              onClick={onDemote}
              data-tip={_(i18n, 'Disable Admin access')}
            >
              <User20 />
            </div>
            <div
              className={classNames('dim pa2 pointer', {
                dn: isAdmin || !isPaid || isInvited,
              })}
              onClick={onPromote}
              data-tip={_(i18n, 'Assign Admin access')}
            >
              <IbmSecurity20 />
            </div>
            <div
              className={classNames('dim pa2 pointer', {
                dn: isInvited,
              })}
              onClick={() => {
                setSelectedUser(username);
                dispatch(
                  Map({
                    type: 'SET_CONTEXTUAL_DRAWER',
                    drawer: 'EDIT_USERS',
                  }),
                );
              }}
              data-tip={_(i18n, 'Edit User')}
            >
              <Edit20 />
            </div>
            <div
              className={classNames('mr2 dim pa2 pointer', {
                dn: !isInvited,
              })}
              onClick={onResend}
              data-tip={_(i18n, 'Resend Invitation Email')}
            >
              <Reset20 />
            </div>

            <DeleteUserButton isInvite={isInvited} handleDelete={isInvited ? onDeleteInvite : onDelete} />

            {/* <Popover>
              <Popover.Trigger className={classNames('mr2 dim pa2 pointer')}>
                <TrashCan16 data-tip="Delete" className="fill--red" />
              </Popover.Trigger>
              <Popover.Content
                type="confirmation"
                title="Delete user"
                warning="This cannot be undone."
              >
                <button
                  className="bg-black bg-animate pv2 w-25 ml-auto hover-bg-red ph3 white fw-medium fs-s"
                  onClick={() => null}
                >
                  Yes
                </button>
              </Popover.Content>
            </Popover> */}
          </div>
        </td>
      </tr>
    </>
  );
};

const EditUserBlade = ({ state, dispatch, defaultState }) => {
  const [model, setModel] = useState(defaultState);
  useEffect(() => {
    setModel(defaultState);
  }, [defaultState]);

  return (
    <>
      <Blade
        title="Edit User"
        state={state}
        dispatch={dispatch}
        show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'EDIT_USERS'}
        onClose={() => setModel(defaultState)}
      >
        <div>
          <div className={'ma3'}>
            <Input
              title={'Full Name'}
              maxLength={80}
              value={model.get('fullName', '')}
              onChange={e => {
                setModel(model.set('fullName', e.target.value));
              }}
              inverse
            />
          </div>
          {/* <div className={'ma3'}>
            <Input
              title={'First Name'}
              maxLength={80}
              value={model.get('firstName', '')}
              onChange={e => {
                setModel(model.set('firstName', e.target.value));
              }}
              inverse
            />
          </div>
          <div className={'ma3'}>
            <Input
              title={'Last Name'}
              maxLength={80}
              value={model.get('lastName', '')}
              onChange={e => {
                setModel(model.setIn(['lastName'], e.target.value));
              }}
              inverse
            />
          </div> */}
          <div className={'ma3'}>
            <Input
              title={'Email'}
              disabled
              error={model.get('error') && !model.get('email') ? 'Invalid email.' : null}
              inverse
              value={model.get('email', '')}
              onChange={e => setModel(model.set('email', e.target.value))}
            />
          </div>

          <div className={'ma3'}>
            <Input
              title={'Job Title'}
              inverse
              value={model.get('jobTitle', '')}
              onChange={e => {
                setModel(model.set('jobTitle', e.target.value));
              }}
            />
          </div>

          <div className={'ma3'}>
            <Input
              title={'Phone'}
              error={
                model.get('error') &&
                model.get('phone') &&
                (model.get('phone').length < minPhoneLength || model.get('phone').length > maxPhoneLength)
                  ? 'Invalid phone number.'
                  : null
              }
              inverse
              value={model.get('phone', '')}
              onChange={e => {
                setModel(model.set('phone', e.target.value));
              }}
            />
          </div>
          <div className="ma3">
            <Button
              text={'Save'}
              small
              onClick={() => {
                const userEdit = model;
                dispatch(Map({ type: 'CLEAR_CONTEXTUAL_DRAWER' }));
                dispatch(
                  Map({
                    type: 'UPDATE_USER',
                    userEdit,
                  }),
                );
              }}
              icon={<MailAll20 className={'white fill--white'} />}
            />
          </div>
        </div>
      </Blade>
    </>
  );
};

const InviteUser = ({ state, dispatch }) => {
  const licenses = state.getIn(['organization', 'licenses'], 0);
  const usedLicenses = state.get('users', Map()).filter(u => u.get('roles', Set()).has('PAID')).size;
  const isAdmin = state
    .getIn(['users', state.get('username')], Map())
    .get('roles', List())
    .has('ADMIN');

  return (
    <div className={'mb3'}>
      <div className={'f2 fw7'}>{`${usedLicenses} of ${licenses > -1 ? licenses : 'unlimited'}`}</div>
      <div className={'i18n f6 fw7 mb3'}>Licenses</div>
      <div className={'mw5'}>
        {isAdmin ? (
          <Button
            small
            className="i18n"
            text={'Invite Users'}
            icon={<UserFollow20 className={'white fill--white'} />}
            onClick={() => dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'INVITE_USER' }))}
          />
        ) : null}
      </div>
    </div>
  );
};

const InviteUserBlade = ({ state, dispatch }) => {
  const defaultState = Map({
    invitees: List([
      Map({
        email: '',
        first_name: '',
        last_name: '',
        job_title: '',
        phone: '',
      }),
    ]),
  });
  const [model, setModel] = useState(defaultState);

  const onSubmit = () => {
    const newMembers = model.get('invitees');
    const allValid = newMembers.every(i => i.get('email') || validate(i.get('email')));
    const isAllNameValid = newMembers.every(i => i.get('first_name') && i.get('last_name'));
    const isPhoneValid = newMembers.every(
      i =>
        !i.get('phone') ||
        (i.get('phone') && i.get('phone').length >= minPhoneLength && i.get('phone').length <= maxPhoneLength),
    );
    if (allValid && isAllNameValid && isPhoneValid) {
      newMembers
        .filter(i => validate(i.get('email')))
        .forEach(member => dispatch(Map({ type: 'INVITE_EMAIL', member })));
      setModel(defaultState);
    } else {
      setModel(model.set('error', true));
    }
  };

  return (
    <Blade
      title="Invite User"
      state={state}
      dispatch={dispatch}
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'INVITE_USER'}
      onClose={() => setModel(defaultState)}
    >
      {model.get('invitees').map((invitee, i) => {
        return (
          <div key={i}>
            <div className={'ma3'}>
              <Input
                title={'First Name'}
                key={`_${i}`}
                error={model.get('error') && !model.getIn(['invitees', i, 'first_name']) ? 'Invalid first name.' : null}
                inverse
                value={invitee.get('first_name', '')}
                onChange={e => {
                  setModel(model.setIn(['invitees', i, 'first_name'], e.target.value));
                }}
              />
            </div>
            <div className={'ma3'}>
              <Input
                title={'Last Name'}
                key={`_${i}`}
                error={model.get('error') && !model.getIn(['invitees', i, 'last_name']) ? 'Invalid last name.' : null}
                inverse
                value={invitee.get('last_name', '')}
                onChange={e => setModel(model.setIn(['invitees', i, 'last_name'], e.target.value))}
              />
            </div>
            <div className={'ma3'}>
              <Input
                title={'Job Title'}
                key={`_${i}`}
                inverse
                value={invitee.get('job_title', '')}
                onChange={e => {
                  setModel(model.setIn(['invitees', i, 'job_title'], e.target.value));
                }}
              />
            </div>
            <div className={'ma3'}>
              <Input
                title={'Phone'}
                key={`_${i}`}
                error={
                  model.get('error') &&
                  model.getIn(['invitees', i, 'phone']) &&
                  (model.getIn(['invitees', i, 'phone']).length < minPhoneLength ||
                    model.getIn(['invitees', i, 'phone']).length > maxPhoneLength)
                    ? 'Invalid phone number.'
                    : null
                }
                inverse
                value={invitee.get('phone', '')}
                onChange={e => {
                  setModel(model.setIn(['invitees', i, 'phone'], e.target.value));
                }}
              />
            </div>
            <div className={'ma3'}>
              <Input
                className={'mb2'}
                key={`_${i}`}
                title={'Email Address'}
                error={
                  model.get('error') && !validate(model.getIn(['invitees', i, 'email']))
                    ? 'Invalid email address.'
                    : null
                }
                type={'email'}
                inverse
                value={invitee.get('email')}
                onChange={e => setModel(model.setIn(['invitees', i, 'email'], e.target.value))}
              />
            </div>
          </div>
        );
      })}
      <div
        onClick={() =>
          setModel(
            model
              .update('invitees', invs => invs.push(Map({ email: '', first_name: '', last_name: '' })))
              .set('error', ''),
          )
        }
        className={'blue underline dim pointer mb4 ma3'}
      >
        + Add another <br />
      </div>
      <div className="ma3">
        <Button
          text={'Invite to EasyRCA'}
          small
          onClick={onSubmit}
          icon={<MailAll20 className={'white fill--white'} />}
        />
      </div>
    </Blade>
  );
};

export const NewEquipmentFacilityViewer = ({
  state,
  dispatch,

  isAdmin,
  uploadType,
  url,
}) => {
  // const [listData, setListData] = useState(List([]));
  // const [loader, setLoader] = useState(true);
  const isViewingEquipment = uploadType === 'equipment';

  // useEffect(() => {
  //   setListData(list);
  //   setLoader(false);
  // }, [list]);

  // const paginate = array => {
  //   // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  //   //
  //   // ...usually?
  //   return array
  //     .sortBy(item => item.get(keys.main_menu_key).get('name').toLowerCase())
  //     .slice((page - 1) * count, page * count);
  // };

  // useEffect(() => {
  //   ReactTooltip.rebuild();
  // }, [listData]);

  // const [newFacilityName, setNewFacilityName] = React.useState(null);
  // const [newSiteName, setNewSiteName] = React.useState(null);
  // const [newDepartmentName, setNewDepartmentName] = React.useState(null);

  // const facilityNameIsInvalid = React.useMemo(() => {
  //   const matchingFacility = listData.find(f => {
  //     const name = f.getIn([keys.main_menu_key, 'name']);
  //     const isEqual =
  //       name && newFacilityName
  //         ? name.toLowerCase() === newFacilityName.toLowerCase()
  //         : false;

  //     return isEqual;
  //   });

  //   return matchingFacility;
  // }, [newFacilityName, keys.main_menu_key, listData]);
  // const siteNameIsInvalid = React.useMemo(() => {
  //   const matchingSite = listData.find(f => {
  //     const name = f.getIn([keys.sub_menu_key, 'name']);
  //     const isEqual =
  //       name && newSiteName
  //         ? name.toLowerCase() === newSiteName.toLowerCase()
  //         : false;

  //     return isEqual;
  //   });

  //   return matchingSite;
  // }, [newSiteName, keys.sub_menu_key, listData]);
  // const departmentNameIsInvalid = React.useMemo(() => {
  //   const matchingDepartment = listData.find(f => {
  //     const name = f.getIn([keys.final_key, 'name']);
  //     const isEqual =
  //       name && newDepartmentName
  //         ? name.toLowerCase() === newDepartmentName.toLowerCase()
  //         : false;

  //     return isEqual;
  //   });

  //   return matchingDepartment;
  // }, [newDepartmentName, keys.final_key, listData]);

  // const updateFacilityName = debounce(
  //   event => setNewFacilityName(event.target.value),
  //   225,
  // );
  // const updateSiteName = debounce(
  //   event => setNewSiteName(event.target.value),
  //   225,
  // );
  // const updateDepartmentName = debounce(
  //   event => setNewDepartmentName(event.target.value),
  //   225,
  // );
  // const dispatchCrudTypes = React.useMemo(() => {
  //   const objectTypeSuffix = isViewingEquipment ? 'EQUIPMENT' : 'FACILITY';
  //   return {
  //     ADD: `ADD_${objectTypeSuffix}`,
  //     UPDATE: `UPDATE_${objectTypeSuffix}`,
  //     DELETE: `DELETE_${objectTypeSuffix}`,
  //   };
  // }, [isViewingEquipment]);
  // const objectUuidKey = isViewingEquipment ? 'equipmentUuid' : 'facilityUuid';

  // const handleCreate = React.useCallback(
  //   (name, parentUuid, type = keys.main_menu_key) => {
  //     const payload = isViewingEquipment
  //       ? {
  //           equipment: {
  //             name,
  //             equipmentType: type,
  //             parentUuid,
  //           },
  //         }
  //       : {
  //           facility: {
  //             name,
  //             facilityType: type,
  //             parentUuid,
  //           },
  //         };

  //     dispatch({
  //       type: dispatchCrudTypes.ADD,
  //       ...payload,
  //     });
  //     // setListData(s => s.insert(Map({ name: newFacilityName })));
  //   },
  //   [dispatch, dispatchCrudTypes, isViewingEquipment, keys],
  // );

  // const handleCreateNewChild = React.useCallback(
  //   (name, parentUuid) => {
  //     dispatch({
  //       type: 'ADD_FACILITY',
  //       facility: {
  //         name,
  //         facilityType: 'site',
  //         parentUuid,
  //       },
  //     });
  //     // setListData(s => s.insert(Map({ name: newFacilityName })));
  //   },
  //   [dispatch],
  // );
  // const handleCreateNewGrandChild = React.useCallback(
  //   (name, parentUuid) => {
  //     dispatch({
  //       type: 'ADD_FACILITY',
  //       facility: {
  //         name,
  //         facilityType: 'department',
  //         parentUuid,
  //       },
  //     });
  //     // setListData(s => s.insert(Map({ name: newFacilityName })));
  //   },
  //   [dispatch],
  // );

  // const handleUpdate = React.useCallback(
  //   (name, uuid) => {
  //     const payload = isViewingEquipment
  //       ? {
  //           equipment: {
  //             equipmentUuid: uuid,
  //             name,
  //           },
  //         }
  //       : {
  //           facility: {
  //             facilityUuid: uuid,
  //             name,
  //           },
  //         };

  //     dispatch({
  //       type: dispatchCrudTypes.UPDATE,
  //       ...payload,
  //     });
  //   },
  //   [dispatch, dispatchCrudTypes, isViewingEquipment],
  // );

  // const handleDelete = React.useCallback(
  //   uuid => {
  //     const payload = isViewingEquipment
  //       ? {
  //           equipment: {
  //             equipmentUuid: uuid,
  //           },
  //         }
  //       : {
  //           facility: {
  //             facilityUuid: uuid,
  //           },
  //         };
  //     dispatch({
  //       type: dispatchCrudTypes.DELETE,
  //       ...payload,
  //     });
  //   },
  //   [dispatch, dispatchCrudTypes, isViewingEquipment],
  // );

  // if (loader) {
  //   return (
  //     <div className={'w-100 flex items-center black'}>
  //       <div className="spin blue">
  //         <Renew32 />
  //       </div>
  //       <div className={'ml3 f3'}>Loading {isViewingEquipment ? 'Equipment' : 'Facilities'}</div>
  //     </div>
  //   );
  // }

  return (
    <div className="w-100">
      <div className="w-50 mb5">
        <p>
          EasyRCA is being upgraded with a new structure for managing the Equipment and Facility hierarchies. This new
          structure will allow for more levels in the hierarchy so the equipment listing in EasyRCA is much more in line
          with the functional location hierarchy being commonly used in many CMMS systems across multiple industries.
        </p>
        <p>
          With the new structure, you can now tag an RCA with a specific piece of equipment just by doing a quick search
          on an Equipment ID#/serial # or search just on a description.
        </p>
        <p>
          When we transition to the new system, the current Equipment and Facilities Management pages will be
          discontinued.
        </p>
      </div>
      <section className="w-50">
        <header className="mb5">
          <h1 className="f2.5 fw-regular">FAQs</h1>
        </header>
        <div className="faqs flex flex-column items-stretch justify-start">
          <div className="faq flex flex-column mb4">
            <strong>Q: What's going to happen to my existing Facilities and Equipment Structure?</strong>
            <p>
              A: Your current Facilities and Equipment structures are not going anywhere. <br />
              They are now converted into the new format and ready for you to use.
            </p>
          </div>
          <div className="faq flex flex-column">
            <strong>
              Q: What if I would like to convert my current 3-level hierarchy in EasyRCA to 7 or more levels?
            </strong>
            <p>
              A: No problem! Contact us at <a href="mailto:support@reliability.com">support@reliability.com</a> and our
              team will work with you to update your hierarchy.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export const Admin = ({ state, dispatch }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const rawUsers = state.get('users');

  const username = state.get('username');
  const iAmAdmin = state.getIn(['users', username, 'roles'], Set()).has('ADMIN');
  const userToEdit = state.getIn(['users', selectedUser], Map());
  const companyName = state.getIn(['organization', 'companyName']);
  const licenses = state.getIn(['organization', 'licenses'], 0);
  const paidUsers = state.get('users', Map()).filter(u => u.get('roles', Set()).has('PAID')).size;

  const hasLicenses = licenses - paidUsers > 0 || licenses === -1;
  const ws = state.get('ws');

  const [isInitializing, setIsInitializing] = React.useState(true);
  const groups = state.get('groups', []);

  React.useEffect(() => {
    if (ws) {
      // view is rendering for the first time
      // ...fetch groups and flip initialization flag
      if (isInitializing) {
        // make sure we don't have any holes in store
        dispatch(Map({ type: 'GET_ORGANIZATION' }));
        dispatch(Map({ type: 'LIST_GROUPS', username }));
        return setIsInitializing(false);
      }
    } else {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          return dispatch(Map({ type: 'SET_URL', url: '/' }));
        }

        if (isInitializing) {
          // make sure we don't have any holes in store
          dispatch(Map({ type: 'GET_ORGANIZATION' }));
          dispatch(
            Map({
              type: 'LIST_GROUPS',
              username,
            }),
          );
          return setIsInitializing(false);
        }
      });
    }
  }, [dispatch, isInitializing, username, ws]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [totalPages, setTotalPages] = useState(List([]));
  const [minPageNumberSet, setMinPageNumberSet] = useState(1);
  const [maxPageNumberSet, setMaxPageNumberSet] = useState(10);
  const [page, setPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');
  const users = useMemo(() => {
    if (!rawUsers || rawUsers.size < 1) return List();

    const userData =
      searchQuery !== '' && rawUsers
        ? rawUsers.filter(u => {
            const name = u.get('fullName', '');
            const username = u.get('username', '');

            // sometimes user objects are missing fullName and/or username
            if (!name || !username) return false;

            const lowerCaseName = name.toLowerCase();
            const lowerCaseUsername = username.toLowerCase();
            const lowerCaseQuery = searchQuery.toLowerCase();

            return lowerCaseName.includes(lowerCaseQuery) || lowerCaseUsername.includes(lowerCaseQuery);
          })
        : rawUsers;

    if (searchQuery !== '' && page !== 1) {
      // if (userData.size > maxPageNumberSet) {
      setPage(1);
      // }
    }

    return userData.sort((a, b) => {
      try {
        const nameA = a.get('fullName') || 'N/A';
        const nameB = b.get('fullName') || 'N/A';
        // attempt to get the last name out of a full name string
        // ...filter the split results to remove any instances of ' ' (extra space between first and last names)
        const lastNameA = nameA
          .split(' ')
          .filter(f => f !== ' ')
          .pop();
        const lastNameB = nameB
          .split(' ')
          .filter(f => f !== ' ')
          .pop();
        // locale comparison results for entire name
        // const result = nameA.localeCompare(nameB);

        // locale comparison results for last name only
        const lastnameResult = lastNameA.localeCompare(lastNameB);

        // console.groupCollapsed(`${nameA} – ${nameB}`);
        // console.info({
        //   nameA,
        //   nameB,
        //   lastNameA,
        //   lastNameB,
        //   lastnameResult:
        //     lastnameResult && lastnameResult === -1
        //       ? `${nameA} is less than ${nameB}`
        //       : `${nameA} is greater than ${nameB}`,
        // });
        // console.groupEnd();

        return lastnameResult;
      } catch (error) {
        console.warn(error);
      }
    });
  }, [rawUsers, searchQuery]);

  const count = 20;
  const onPageChange = value => {
    if (totalPages.size >= value) {
      setPage(value);
    }
  };

  const onClickPageSet = type => {
    if (type === 'next') {
      if (totalPages.size > maxPageNumberSet) {
        let minPageSet = minPageNumberSet + 10;
        let maxPageSet = maxPageNumberSet + 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    } else {
      if (totalPages.size > minPageNumberSet && minPageNumberSet > 1) {
        let minPageSet = minPageNumberSet - 10;
        let maxPageSet = maxPageNumberSet - 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    }
  };

  useEffect(() => {
    if (!ws) {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          dispatch(Map({ type: 'SET_URL', url: '/' }));
        }
      });
    } else {
      dispatch(Map({ type: 'GET_ORGANIZATION' }));
    }
  }, [ws, dispatch]);

  useEffect(() => {
    if (users.size > 1) {
      let totalPageValue = Math.ceil(users.size / count);
      setTotalPages(Range(1, totalPageValue + 1).toList());
      dispatch(Map({ type: 'GET_ROLES_USERS', members: users.keySeq() }));
    }
  }, [users.size, dispatch]); /* eslint-disable-line */

  const paginate = array => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.keySeq().slice((page - 1) * count, page * count);
    // return array;
  };

  return (
    <Page state={state} dispatch={dispatch} title="Settings">
      <InviteUserBlade state={state} dispatch={dispatch} />
      {selectedUser ? <EditUserBlade state={state} dispatch={dispatch} defaultState={userToEdit} /> : null}
      <div className={classNames('mb4 red', { dn: licenses < paidUsers })}>
        <span className="i18n">You may request more licenses by inviting users or by emailing</span> &nbsp;
        <a className={'blue'} href={'mailto:sebastian@reliability.com'}>
          licenses@reliability.com.
        </a>
      </div>
      <div className="bb">
        <div className="flex items-stretch justify-start">
          <InviteUser state={state} dispatch={dispatch} />
          <div className="w-33 ml-auto flex items-end" style={{ paddingBottom: 20 }}>
            <input
              className="pv2 ph2 w-100"
              type="search"
              placeholder="Search by name or email"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="content_table">
          <div className="w-100 center">
            <div className="cf">
              <div className="fl w-100">
                <div className="bg-white">
                  <table className="collapse w-100 ba br2 b--black-10 pv2 ph3">
                    <thead>
                      <tr className="striped--light-gray">
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Name</th>
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Email</th>
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Phone</th>
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Job Title</th>
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Role</th>
                        <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Groups</th>
                        {iAmAdmin && (
                          <th className="i18n bg-light-gray pv2 ph3 tl fw6 mt1 ttc ba b--black-70 w-20">Actions</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {paginate(users).map(u => {
                        const groupsForUser = groups.filter(g => {
                          const members = g.get('members');
                          const admins = g.get('admins');

                          return members.includes(u) || admins.includes(u);
                        });

                        return (
                          <UserCard
                            state={state}
                            dispatch={dispatch}
                            key={u}
                            groups={groupsForUser}
                            username={u}
                            canAssign={hasLicenses}
                            setSelectedUser={setSelectedUser}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {totalPages.size > 0 ? (
        <div className="tc mw8 center mt2">
          <div className="dib overflow-hidden ba br2 b--light-silver">
            <nav className="cf" data-name="pagination-numbers-bordered">
              <button
                className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                onClick={() => {
                  if (page > 1) {
                    let newPage = page - 1;
                    onPageChange(newPage);
                  }
                }}
                title="Previous"
              >
                &larr; <span className="i18n">Previous</span>
              </button>
              <button
                className="fr dib link dim black f6 f5-ns b pa3 bg-white"
                onClick={() => {
                  if (page !== totalPages) {
                    let newPage = page + 1;
                    onPageChange(newPage);
                  }
                }}
                title="Next"
              >
                <span className="i18n">Next</span> &nbsp;&rarr;
              </button>
              {totalPages.size >= maxPageNumberSet ? (
                <>
                  <button
                    className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                    onClick={() => {
                      onClickPageSet('prev');
                    }}
                    title="Previous"
                  >
                    <PreviousFilled20 />
                  </button>
                  <button
                    className="fr dib link dim black f6 f5-ns b pa3 br bg-white"
                    onClick={() => {
                      onClickPageSet('next');
                    }}
                    title="Next"
                  >
                    <NextFilled20 />
                  </button>
                </>
              ) : null}

              <div className="overflow-hidden center dt tc">
                {totalPages
                  .filter(i => i >= minPageNumberSet && i <= maxPageNumberSet)
                  .map(x => (
                    <button
                      className="dtc link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                      onClick={() => onPageChange(x)}
                      title={x}
                      key={x}
                    >
                      {x}
                    </button>
                  ))}
              </div>
            </nav>
          </div>
        </div>
      ) : null}
    </Page>
  );
};
